import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { Line, Polar, Bar } from 'react-chartjs-2';
import moment from 'moment';
import Text from '../components/forms/fields/text';
import {
    Container,
    Row,
    Col,
    Button,
} from 'reactstrap';


class HomePage extends Component {
    constructor(props) {
        super(props);
        let cur = new Date();
        let start = new Date(cur.getFullYear(), cur.getMonth(), 1, 0, 0, 0, 0).getTime() / 1000;
        let end = new Date(cur.getFullYear(), cur.getMonth() + 1, 0, 23, 59, 59, 0).getTime() / 1000;


        this.state = {
            activeClients: 0,
            from: moment.unix(start).format('DD.MM.YYYY'),
            to: moment.unix(end).format('DD.MM.YYYY'),
            subtotal: 0,
            tax: 0,
            shipping: 0,

            productVisits: [],
            products: []
        };
    }


    componentDidMount() {
        //this.props.handleLoader(true);
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient && this.props.uData) {
            this.registerSocketIOEvents();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('adminDataStatistics', (data) => {
            console.log(data);

            this.setState(data);
        });

        this.props.socketIOClient.emit('adminDataStatistics', { from: this.state.from, to: this.state.to });
    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("adminFetch");
    }


    render() {
        let sum1 = 0;
        let sum2 = 0;
        let sum3 = 0;

        for(let i=0;i<this.state.products.length;i++){
            sum1+=parseFloat(this.state.products[i].price);
            sum2+=this.state.products[i].quantity;
            sum3+=(this.state.products[i].price * this.state.products[i].quantity);

        }

        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <Container fluid>

                    <div className="panel ">
                        <div className="panel-title">
                            <h1>{this.props.translate('Dashboard')}</h1>
                            <h6>{this.props.translate('Statistika')}</h6>

                        </div>


                    </div>

                    <Row>
                        <Col lg="2">
                            <Text placeholder="Od" value={this.state.from} onChange={(from) => this.setState({ from })} lang={this.props.lang} translate={this.props.translate} />
                        </Col>
                        <Col lg="2">
                            <Text placeholder="Do" value={this.state.to} onChange={(to) => this.setState({ to: to })} lang={this.props.lang} translate={this.props.translate} />
                        </Col>
                        <Col lg="3" style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 12 }}>
                            <Button color="primary" onClick={() => {
                                this.props.socketIOClient.emit('adminDataStatistics', { from: this.state.from, to: this.state.to });
                            }}>Prikazi</Button>
                        </Col>


                        <Col lg="12" className={"table table-no-image"}>
                            <table>
                                <tr>
                                    <th>Broj narudzbi</th>
                                    <th>Broj narudzbi sa besplatnom dostavom</th>

                                    <th>Iznos bez PDV-a</th>
                                    <th>Iznos PDV-a</th>
                                    <th>Iznos dostava</th>

                                </tr>
                                <tr>
                                <td >{this.state.ordersCount} </td>
                                <td >{this.state.ordersCountFreeShipping} </td>

                                    <td >{this.state.subtotal.toFixed(2)} EUR</td>
                                    <td>{this.state.tax.toFixed(2)} EUR</td>
                                    <td>{this.state.shipping.toFixed(2)} EUR</td>
                                </tr>

                            </table>
                            <table>
                                <tr>
                                    <th>Naziv proizvoda</th>
                                    <th>Naziv pakovanja</th>

                                    <th>Cijena [EUR]</th>
                                    <th>Kolicina</th>
                                    <th>Iznos [EUR]</th>

                                </tr>
                                {
                                    this.state.products.map((item, idx) => {
                                        return (
                                            <tr>
                                            <td >{item.productName} </td>
                                            <td >{item.packageName} </td>
            
                                                <td >{parseFloat(item.price).toFixed(2)}</td>
                                                <td>{item.quantity}</td>
                                                <td>{parseFloat(item.price * item.quantity).toFixed(2)}</td>
                                            </tr>
            
                                        )
                                    })
                                }
                                <tr>
                                    <td></td>
                                    <td></td>

                                    <td>{}</td>
                                    <td>{sum2}</td>
                                    <td>{parseFloat(sum3).toFixed(2)}</td>

                                </tr>

                            </table>

                        </Col>
                    </Row>



                </Container>
            </div>



        );
    }
}

export default PageWithLayout(HomePage);