
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import FileMultipleV2 from './fileMultipleV2';

class FileMultipleV2Wrap extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidUpdate(prevProps){
        if (!prevProps.value && this.props.value  && Array.isArray(this.props.value)){
            let value = {};
            value[this.props.lang] = this.props.value;
            this.props.onChange(value);
        }

    }
    
    render() {
console.log(this.props.lang, this.props.value)
        if (this.props.lang == 'de'){
            return (
                <FileMultipleV2 lang={this.props.lang} translate={this.props.translate} label={this.props.label} value={this.props.value && this.props.value.de ? this.props.value.de : this.props.value} onChange={(val) => {
                    let value = this.props.value;
                    if (!value){
                        value = {};
                    }
                    value.de = val;
                    this.props.onChange(value);
                }}></FileMultipleV2>
            )
        }else if (this.props.lang == 'en'){
            return (
                <FileMultipleV2 lang={this.props.lang} translate={this.props.translate} label={this.props.label} value={this.props.value && this.props.value.en ? this.props.value.en : this.props.value} onChange={(val) => {
                    let value = this.props.value;
                    if (!value){
                        value = {};
                    }

                    value.en = val;
                    this.props.onChange(value);
                }}></FileMultipleV2>
            )

        }else if (this.props.lang == 'it'){
            return (
                <FileMultipleV2 lang={this.props.lang} translate={this.props.translate} label={this.props.label} value={this.props.value && this.props.value.it ? this.props.value.it : this.props.value} onChange={(val) => {
                    let value = this.props.value;
                    if (!value){
                        value = {};
                    }

                    value.it = val;
                    this.props.onChange(value);
                }}></FileMultipleV2>
            )

        }else if (this.props.lang == 'si'){
            return (
                <FileMultipleV2 lang={this.props.lang} translate={this.props.translate} label={this.props.label} value={this.props.value && this.props.value.si ? this.props.value.si : this.props.value} onChange={(val) => {
                    let value = this.props.value;
                    if (!value){
                        value = {};
                    }

                    value.si = val;
                    this.props.onChange(value);
                }}></FileMultipleV2>
            )

        }else if (this.props.lang == 'hr'){
            return (
                <FileMultipleV2 lang={this.props.lang} translate={this.props.translate} label={this.props.label} value={this.props.value && this.props.value.hr ? this.props.value.hr : this.props.value} onChange={(val) => {
                    let value = this.props.value;
                    if (!value){
                        value = {};
                    }

                    value.hr = val;
                    this.props.onChange(value);
                }}></FileMultipleV2>
            )

        }
    }
}

export default FileMultipleV2Wrap;